import axios from "axios";
// import { BITRAH_URL } from "../base";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BITRAH_BASE_URL,
});
instance.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";

export default instance;
