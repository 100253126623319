export const MIN_AMOUNTS = {
  btc: 4420000, 
  xrp: 150000,   
  usdt: 37000,
  eth: 2710000,  
  trx: 37000,
  exc: 2530000
};
export const MAX_AMOUNT = 5000000000;

