import authAxios from "./config/need-auth-axios";
import bitrahAxios from "./config/bitrah-axios";
import bitrahFormaxiso from "./config/upload-auth.axios";

class paymentService {
  getAddress(token, coin) {
    return authAxios.post("/manifest/order/address", {
      token: token,
      coin: coin
    });
  }

  getGuestAddress() {
    return bitrahAxios.post("/guest/address");
  }

  getOrderDetails(token) {
    return bitrahAxios.post("/manifest/order/web-info", token);
  }

  guestOrderDetails(token) {
    return bitrahAxios.post("/guest/info", token);
  }
  getMerchantDetails(merchantId) {
    return authAxios.get("/manifest/board/info", merchantId);
  }

  verifyOrder(token) {
    return authAxios.post("/manifest/order/pay", token);
  }

  submitTestPayment(rialValue, coin, merchantId, orderId, callbackUrl) {
    return authAxios.post("/order/submit/wr", {
      rialValue: rialValue,
      coin: coin,
      merchantId: merchantId,
      orderId: orderId,
      callbackUrl: callbackUrl
    });
  }

  submitCustomerEmail(data) {
    return authAxios.post("/order/submit/addEmail", data);
  }

  cashierPayment(rialValue, coin, merchantId) {
    return authAxios.post("/order/cashier/submit/wr", {
      rialValue: rialValue,
      coin: coin,
      merchantId: merchantId
    });
  }

  ATMPayment(rialValue, coin, merchantId) {
    return authAxios.post("/order/pos/submit/wr", {
      rialValue: rialValue,
      coin: coin,
      merchantId: merchantId
    });
  }

  callBackRequest(data) {
    return bitrahFormaxiso.post(`/order/callback`, data);
  }

  requestForSettle(address) {
    return bitrahFormaxiso.post(`/order/settle`, address);
  }

  setTypeOfPayment(type, merchantId) {
    return authAxios.post(`/merchant/panel/update/wr`, {
      calculateRialBalanceAtSettle: type,
      merchantId: merchantId
    });
  }
}

export default new paymentService();
