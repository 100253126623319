import {  MIN_AMOUNTS } from "@/utlities/currenciesminMaxAmount";
export default {
  methods: {
    validateMinValue(value, iso) {
      if (iso === "BTC" && value < MIN_AMOUNTS.btc) {
        return "checkBtcMinAmount";
      } else if (iso === "XRP" && value < MIN_AMOUNTS.xrp) {
        return "checkXrpMinAmount";
      } else if (iso === "TRX" && value < MIN_AMOUNTS.trx) {
        return "checkTrxMinAmount";
      } else if (iso === "USDT" && value < MIN_AMOUNTS.usdt) {
        return "checkUsdtMinAmount";
      } else if (iso === "ETH" && value < MIN_AMOUNTS.eth) {
        return "checkEthMinAmount";
      } else if (iso === "EXC" && value < MIN_AMOUNTS.exc) {
        return "checkExcMinAmount";
      } else {
        return false;
      }
    }
  }
};
