import authAxios from "./config/need-auth-axios";

class userGateWayStatusService {
    getUserGateWay(
        username,
        ip = "",
        businessName,
        url
    ) {
        // return authAxios.get( `/merchant/panel/info/complete/rd?username=${ username }&ip=${ ip }` );
        return authAxios.post("/merchant/panel/info/complete/rd",
            {
                username:username,
                ip:ip,
                businessName:businessName,
                url:url
            });
    }
}

export default new userGateWayStatusService();
