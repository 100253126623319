/* eslint-disable no-useless-escape */
import $formData from "./../../mixins/fromDataGetter";
import $formatNumber from "./../../mixins/formatNumber";
import $preventToEnterNegativeNumber from "./../../mixins/preventToEnterNegativeNumber";
import Num2persian from "num2persian";
import { mapGetters, mapActions } from "vuex";
import * as types from "./../../store/types";
import { ASSETS_URL } from "../../services/base";
import paymentService from "../../services/paymentService";
import currenciesService from "../../services/currenciesService";
import userGateWayStatusService from "../../services/userGateWayStatusService";
import { VMoney } from "v-money";
import { MAX_AMOUNT } from "@/utlities/currenciesminMaxAmount";
import $minValidation from "@/mixins/MinValueValidation"

const inputComp = () => import("./../../components/input/index.vue");

export default {
  name: "dashboard-exchange-card",
  components: {
    inputComp
  },
  props: [],
  mixins: [$formData, $formatNumber, $preventToEnterNegativeNumber, $minValidation],
  directives: { money: VMoney },
  data() {
    return {
      baseUrl: ASSETS_URL,
      show: true,
      validate: {
        isvalid: true,
        message: "شماره موبایل به درستی وارد نشده است"
      },
      loading: false,
      ValueToCalculate: "",
      convertedIcon: "",
      convertedTitle: "",
      convertedISO: "",
      money: {
        thousands: ",",
        precision: false,
        masked: false /* doesn't work with directive */
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
      currencies: types.GET_CURRENCIES,
      bitrahCurrencies: types.GET_BITRAH_CURRENCIES,
      userGateway: types.GET_USER_GATEWAY_STATUS
    }),
    toPersian() {
      if (this.ValueToCalculate) {
        parseFloat(this.ValueToCalculate);
        let toValue = this.ValueToCalculate.replace(/\,/g, "");
        return Num2persian((toValue / 10).toFixed(0));
      } else {
        return 0;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.getUserGatewayStatusData();
    }, 200);
    this.getBitrahCurrenciesFunc();
  },
  methods: {
    ...mapActions({
      bitrahCurrenciesAction: types.ACTION_BITRAH_CURRENCIES,
      userGateWayAction: types.ACTION_USER_GATEWAY_STATUS
    }),
    getUserGatewayStatusData() {
      userGateWayStatusService
        .getUserGateWay(this.userInfo.username, this.IPAddress)
        .then(response => {
          this.userGateWayAction(response.data);
        })
        .catch(response => {
          this.$bvToast.toast(response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    getBitrahCurrenciesFunc() {
      currenciesService
        .getBitrahCurrencies()
        .then(response => {
          this.bitrahCurrenciesAction(response.data);
          this.convertedIcon = response.data[0].logo;
          this.convertedTitle = response.data[0].name;
          this.convertedISO = response.data[0].iso;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    // DUE TO SOME ISSUES WITH THIS METHOD I HAVE ADDED THE V-MONEY DIRECTIVE
    // formatNumberFunc() {
    //   if (
    //     this.ValueToCalculate === 0 ||
    //     this.ValueToCalculate === "0" ||
    //     this.ValueToCalculate === ""
    //   ) {
    //     this.ValueToCalculate = 0;
    //   }
    //   this.ValueToCalculate = this.$formatNumber(
    //     parseFloat(this.ValueToCalculate.replace(/\,/g, ""))
    //   );
    // },
    changeCurrency(currency) {
      this.convertedIcon = currency.logo;
      this.convertedTitle = currency.name;
      this.convertedISO = currency.iso;
    },
    submit() {
      if (this.ValueToCalculate == 0) {
        this.$bvToast.toast(this.$i18n.t("panel.merchant.pay.checkZeroValue"), {
          title: this.$i18n.t("toast.errorTitle"),
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else if (
        this.validateMinValue(
          this.ValueToCalculate.replace(/\,/g, ""),
          this.convertedISO
        )
      ) {
        // validateMinValue is defined as a mixin
        let minAmountMsg = this.validateMinValue(
          this.ValueToCalculate.replace(/\,/g, ""),
          this.convertedISO
        );
        this.$bvToast.toast(
          this.$i18n.t(`panel.merchant.pay.${minAmountMsg}`),
          {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
      } else if (this.ValueToCalculate.replace(/\,/g, "") > MAX_AMOUNT) {
        this.$bvToast.toast(this.$i18n.t("panel.merchant.pay.checkMaxAmount"), {
          title: this.$i18n.t("toast.errorTitle"),
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else {
        paymentService
          .cashierPayment(
            this.ValueToCalculate.replace(/\,/g, ""),
            this.convertedISO,
            localStorage.getItem("bitrahAccessToken")
          )
          .then(response => {
            window.open(
              "/" +
                `${this.$i18n.locale}` +
                "/" +
                `${this.userInfo.merchants[0].slug}` +
                "?token=" +
                response.data +
                "&merchantId=" +
                localStorage.getItem("bitrahAccessToken") +
                "&mode=" +
                "off" +
                "&cashier=" +
                true +
                "&coin=" +
                this.convertedISO +
                "&amount=" +
                this.ValueToCalculate.replace(/\,/g, "")
            );
          })
          .catch(response => {
            this.$bvToast.toast(response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          });
      }
    }
  }
};
