export default {
    data() {
        return {
            form: {},
        };
    },
    methods: {
        getFormData(evt) {
            let key = Object.keys(evt);
            this.form[key] = evt[key];
        },
        onSubmit(evt) {
            evt.preventDefault();
            // alert(JSON.stringify(this.form));
        },
        onReset(evt) {
            evt.preventDefault();
            this.form = {};
        },
    },
};
